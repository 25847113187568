.signupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.signupBox {
  display: flex;
  width: 750px;
  max-width: 90%; 
  height: 550px;
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.imageContainer {
  flex: 1.2;
  background-image: url("../../images/quranImg.jpg");
  background-size: cover;
  background-position: center;
}

.formContainer {
  flex: 1;
  padding: 20px;
}

.tickImage{
  background-image: url("../../images/successTick.png");
}

.signupTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.signupForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signupForm label {
  margin-bottom: 10px;
  text-align: left;
}

.signupForm input {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.signupButton {
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  align-self: flex-end;
}

.phoneInput,
.otpInput {
  display: flex;
  align-items: center;
}

.phoneInput button,
.otpInput button {
  margin-left: 10px;
  padding: 10px;
  font-size: 14px;
  margin-top: -18px;
  border: none;
  background-color: transparent;
  color: #007bff;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


.tickSymbol {
  color: green;
  margin-left: 5px; 
  margin-top: -14px;
}



@media (max-width: 600px) {
  .signupBox {
    width: 100%;
    height: auto;
    min-height: 400px;
  }
  
  .imageContainer {
    flex: 0;
  }
  
  .formContainer {
    padding: 10px;
  }
  
  .signupForm input {
    width: 90%;
  }
}
