.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginBox {
  display: flex;
  width: 750px;
  max-width: 90%; 
  height: 550px;
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.imageContainer {
  flex: 1.2;
  background-image: url("../../images/quranImg.jpg");
  background-size: cover;
  background-position: center;
}

.formContainer {
  flex: 1;
  padding: 20px;
}

.loginTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loginForm label {
  margin-bottom: 10px;
  text-align: left;
}

.loginForm input {
  width: 94%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.loginButton {
  display: block;
  width: 100%;
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.signupLink {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.signupButton {
  background-color: transparent;
  color: #007bff;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.loader {
  display: inline-block;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-top: 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 568px) {
  .loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url("../../images/quranImg.jpg");
    background-size: cover;
    background-position: center;
  }

  .loginBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 90%;
    height: auto;
    background-color: rgba(244, 244, 244, 0.8); /* Adjust the transparency here */
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }

  .imageContainer {
    flex: 1;
    height: 300px;
    display: none;
  }

  .formContainer {
    flex: 1;
    padding: 20px 10px;
  }
}

