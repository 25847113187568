
  .bibleSection{
    background-image: url("../../images/quranImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .logoutButtonContainer{
    border-radius: 10px; 
    background-color: #7b7a7a76; 
    padding: 10px; 
    display: inline-block; 
    top: 20px;
    left: 20px;
    position: absolute;
    cursor: pointer;
  }

  .backButton {
    z-index: 10000;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    height: 100vh;
    /* max-height: 1000px; */
    margin: 0 auto;
  }
  
  .topContent {
    font-size: 22px;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: #f3f3f3;
font-weight: 600  ;
  }
  
  .topContentTwo {
    font-size: 16px;
    margin: 0;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: #f3f3f3;
  
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  
    margin-top: 30px;
    color: #f3f3f3;
  }
  
  .inputContainer h3 {
    font-size: 20px;
    margin-bottom: 0px;
    color: #f3f3f3;
  }
  
  .inputContainer input[type="text"] {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    border-radius: 5px;
    border-color: black;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    font-size: 18px;
  }
  
  .displayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .displayContainer h3 {

    font-size: 18px;
    margin-bottom: 10px;
    color: #f3f3f3;
  }
  
  .displayBox {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 18px;
    height: 130px;
    overflow-y: auto;
    opacity: 0.85;
    color: black;
  }
  
  .submitButtonDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
  
  .submitButton {
    padding: 15px 20px;
    border: none;
    background-color: #a2ffa4;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submitButton:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  }
  
  .instructionsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .instructionsContainer h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .instructionsContainer p {
    margin: 0;
    margin-bottom: 10px;
  }
  
  .instructionsContainer aside {
    font-size: 12px;
  }

  .menuButton {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url("../../images/hamburger.png") no-repeat center;
    background-size: contain;
    z-index: 1000;
  }
  
  .menu {
    position: fixed;
    top: 70px;
    right: 20px;
    width: 200px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    cursor: pointer;
  }
  
  .menu a {
    display: block;
    margin-bottom: 10px;
    color: #000;
    text-decoration: none;
  }
  
  .footer{
    color: #fff;
  }
  
  @media only screen and (max-width: 550px) {
    .instructions-container aside {
      font-size: 10px;
    }
  
    .topContent {
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  
    .topContent-two {
      font-size: 10px;
      margin: 0;
    }

    .menuButton {
      top: 10px;
      right: 10px;
    }
  
    .menu {
      top: 50px;
      right: 10px;
    }
  }